<template>
  <div class="mine-page">
    <Warpper bottom="60">
      <Container ref="scroll">
        <Head title="会员中心">
          <img @click.stop="loginOut" class="quit" src="../../assets/images/top_btn_quit.png" alt />
        </Head>
        <div class="mine-head">
          <div class="head-info">
            <div class="head-face">
              <img v-if="userInfo&&userInfo.pic" :src="userInfo&&userInfo.pic" />
              <img v-else src="../../assets/images/face.jpg">
            </div>
            <div class="head-user">
              <h5>{{userInfo&&userInfo.nickname || userInfo&&userInfo.username}}</h5>
              <p v-if="mark_state==0" @click.stop="toEarnest(1)">保证金管理</p>
              <p v-if="mark_state==1">
                您未取得竞拍资格，请
                <img @click.stop="toEarnest(0)" src="../../assets/images/center_btn_pay.png" /> 保证金
              </p>
            </div>
          </div>

          <ul class="mine-order">
            <li @click="toOrder(1)">
              <img src="../../assets/images/center_btn_unpaid.png" style="width:.71rem" />
              <span>{{order_1}}</span>
            </li>
            <li @click="toOrder(2)">
              <img src="../../assets/images/center_btn_deliver.png" style="width:.72rem" />
              <span>{{order_2}}</span>
            </li>
            <li @click="toOrder(3)">
              <img src="../../assets/images/center_btn_received.png" style="width:.72rem" />
              <span>{{order_3}}</span>
            </li>
            <li @click="toOrder(4)">
              <img src="../../assets/images/center_btn_complete.png" style="width:.7rem" />
              <span>{{order_4}}</span>
            </li>
          </ul>

          <ul class="mine-nav">
            <li @click="toStatement">
              <img style="width:.32rem" src="../../assets/images/ico-cen01.png" alt srcset />
              <span>资金流水</span>
            </li>
            <li @click="toAddress">
              <img style="width:.31rem" src="../../assets/images/ico-cen02.png" alt srcset />
              <span>我的收货地址</span>
            </li>
            <li @click="toCollet">
              <img style="width:.3rem" src="../../assets/images/ico-cen03.png" alt srcset />
              <span>我的收藏</span>
            </li>
            <li @click="toRule">
              <img style="width:.32rem" src="../../assets/images/ico-cen04.png" alt srcset />
              <span>拍卖规则</span>
            </li>
            <li @click="showWxBox">
              <img style="width:.3rem" src="../../assets/images/ico-cen05.png" alt srcset />
              <span>联系我们</span>
            </li>
          </ul>
        </div>
      </Container>
    </Warpper>
    <Tabber :selected="2" />

    <div class="mask" v-show="mask">
      <div class="wx-show-box" v-show="wxBox">
        <h5>扫码关注微信客服</h5>
        <img :src="weixin_pic" alt="wechat" />
        <p>
          客服电话：
          <a :href="'tel:'+weixin_tel">{{weixin_tel}}</a>
        </p>
        <p>客服微信：{{weixin_num}}</p>
        <i @click.stop="wxBox=false" class="close"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Tabber from "components/Tabbar";
import { reqRulePath,reqGetUserCenter } from "@/network/api"
export default {
  components: {
    Tabber
  },
  data() {
    return {
      mask: false,
      wxBox: false,
      userInfo:this.$store.getters.userInfo,
      mark_state: "0",
      order_1: "0",
      order_2: "0",
      order_3: "0",
      order_4: "0",
      weixin_num: "",
      weixin_pic: "",
      weixin_tel: ""
    };
  },
  created(){
    if(!this.userInfo){
      this.$router.replace({ name: "login" });
    }
    reqGetUserCenter({
      user_id:this.userInfo.id
    },false).then(res=>{
      const { code, msg, backdata } = res;
      if (code != 0) return;
      for (const key in backdata.userdata) {
        if (backdata.userdata.hasOwnProperty(key)) {
          this[key] = backdata.userdata[key];
        }
      }
    })
  },
  mounted() {

  },
  methods: {
    loginOut() {
      this.$MessageBox
        .confirm("确定要退出登录么")
        .then(async action => {
          this.$toast({
            message: "退出成功"
          });
          this.$router.replace({ name: "login" });
          setTimeout(()=>{
            this.$store.dispatch('user/fedLogOut')
          })
        })
        .catch(() => {});
    },
    toOrder(index) {
      this.$router.push({
        name: "order",
        query:{
          index
        }
      });
    },
    showWxBox() {
      this.wxBox = true;
    },
    toAddress() {
      this.$router.push({ path: "/address" });
    },
    toStatement() {
      this.$router.push({ path: "/statement" });
    },
    toRule() {
      // window.location.href=reqRulePath;
      this.$router.push({ path: "/rule" });
    },
    toCollet() {
      this.$router.push({
        name: "auction",
        query: {
          index: 4
        }
      });
    },
    toEarnest(index) {
      this.$router.push({ path: "/earnest",query:{state:index} });
    }
  },
  watch: {
    wxBox(v) {
      this.mask = v;
    },
    mask(v) {
      if (!v) {
        this.$refs.scroll && this.$refs.scroll.enable();
        return;
      }
      this.$refs.scroll && this.$refs.scroll.disable();
    }
  }
};
</script>

<style lang="scss" scoped>
.mine-page {
  position: relative;
  width: 100%;
  height: 100%;
  background: #eff0ef;
  .quit {
    width: 20px;
    height: 20px;
  }
}
.mine-head {
  width: 100%;
  height: auto;
  background: transparent;
  padding: 0.88rem 0.3rem 0.2rem;
  box-sizing: border-box;
  position: relative;
  &::before {
    content: "";
    height: 5.84rem;
    background: #62ada2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .head-info {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    position: relative;
    z-index: 1;
    .head-user {
      h5 {
        line-height: 0.4rem;
        font-size: 0.36rem;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: white;
        margin-bottom: 0.22rem;
      }
      p {
        position: relative;
        line-height: 0.24rem;
        font-size: 0.24rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        vertical-align: bottom;
        cursor: pointer;
        img {
          cursor: pointer;
          width: 1.06rem;
          height: 0.52rem;
          position: relative;
          vertical-align: middle;
        }
      }
    }
    .head-face {
      width: 1.36rem;
      height: 1.36rem;
      border-radius: 50%;
      border: 2px solid white;
      overflow: hidden;
      box-sizing: border-box;
      margin-right: 0.28rem;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.mine-order {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.91rem;
  box-sizing: border-box;
  padding: 0 0.2rem;
  li {
    position: relative;
    width: 1rem;
    text-align: center;
    cursor: pointer;
    img {
      height: auto;
    }
    span {
      width: 0.26rem;
      height: 0.26rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      font-size: 0.16rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      background-color: rgb(255, 16, 16);
      top: -0.12rem;
      right: 0.12rem;
    }
  }
}

.mine-nav {
  width: 86.7%;
  height: 6.63rem;
  background: #ffffff;
  border-radius: 0.08rem;
  position: relative;
  margin: 0.63rem auto 0.4rem;
  li {
    height: 1.32rem;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    background: url("../../assets/images/ico-right.png") 95% 50% no-repeat;
    background-size: 0.11rem auto;
    background-color: white;
    padding: 0 0.3rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    font-size: 0.28rem;
    position: relative;
    cursor: pointer;
    img {
      height: auto;
      margin-right: 0.24rem;
    }
    &:first-of-type {
      border-radius: 0.08rem 0.08rem 0 0;
    }
    &:last-of-type {
      border-radius: 0 0 0.08rem 0.08rem;
    }
    &:not(:last-of-type)::after {
      content: "";
      height: 1px;
      background: #e1e1e1;
      position: absolute;
      bottom: 0;
      left: .3rem;
      right: .3rem;
      transform: scaleY(0.5);
    }
  }
}

.mask {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
}

.wx-show-box {
  width: 5.3rem;
  height: 6.22rem;
  background: #ffffff;
  border-radius: 0.08rem;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -(5.3rem/2);
  margin-top: -(6.22rem/2);
  text-align: center;
  z-index: 10000;
  background-image: linear-gradient(
    #5cbfb0 0%,
    #5cbfb0 1.76rem,
    #ffffff 1.76rem,
    #ffffff 100%
  );
  box-sizing: border-box;
  padding-top: 0.33rem;
  h5 {
    width: 100%;
    font-size: 0.38rem;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 0.37rem;
  }
  img {
    width: 3.52rem;
    height: 3.52rem;
    object-fit: cover;
    margin-bottom: 0.26rem;
  }
  p {
    width: 3.2rem;
    font-size: 0.26rem;
    text-align: left;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    margin: 0 auto 0.14rem;
    a {
      font-size: 0.26rem;
      color: #333333;
    }
  }
  i.close {
    width: 0.6rem;
    height: 0.6rem;
    cursor: pointer;
    background: url("../../assets/images/popup_btn_close.png") no-repeat;
    background-size: 100% auto;
    position: absolute;
    right: -0.3rem;
    z-index: 1000;
    top: -0.3rem;
  }
}
</style>